import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import styled, { ThemeProvider } from "styled-components"

import { RichText } from "../api/contentful"
import {
  Section,
  SectionHeading,
  Narrow,
  CallToAction,
  CallToActionVariant,
} from "./shared"
import { contrastSectionTheme } from "./theme"

const JobsBannerSection = styled(Section)`
  padding: 2em 0;
  // TODO: necessary?
  color: ${props => props.theme.color};
  background-color: ${props => props.theme.backgroundColor};
`

const JobsLink = styled(CallToActionVariant)`
  @media only screen and (min-width: ${props => props.theme.maxLayoutWidth}) {
    margin-left: 1rem;
  }
`
const InitativeBewerbung = styled(CallToAction)`
  justify-self: start;
  @media only screen and (max-width: 666px) {
    justify-self: stretch;
  }
  @media only screen and (max-width: 480px) {
    justify-self: start;
  }
`

const Row = styled.div`
  margin-top: 1em;
  display: grid;
  grid-auto-flow: column;
  justify-content: space-between;
  align-items: start;
  gap: 2em;

  @media only screen and (max-width: 666px) {
    grid-auto-flow: row;
    max-width: 80%;
  }
  @media only screen and (max-width: 480px) {
    max-width: 100%;
  }
`

const JobsBanner = ({ withPageLink = true }) => {
  const {
    unsolicitedApplicationHeading,
    unsolicitedApplicationText,
  } = useStaticQuery(graphql`
    query JobsBannerQuery {
      contentfulJobsPage {
        unsolicitedApplicationHeading
        unsolicitedApplicationText {
          json
        }
      }
    }
  `).contentfulJobsPage

  return (
    <ThemeProvider theme={contrastSectionTheme}>
      <JobsBannerSection>
        {withPageLink && <JobsLink to="/jobs">Freie Stellen</JobsLink>}
        <Narrow>
          <Row>
            <div>
              <SectionHeading>{unsolicitedApplicationHeading}</SectionHeading>
              <RichText node={unsolicitedApplicationText} />
            </div>
            <InitativeBewerbung to="mailto:jobs@holzbauplanung.de">
              jobs@holzbau&shy;planung.de
            </InitativeBewerbung>
          </Row>
        </Narrow>
      </JobsBannerSection>
    </ThemeProvider>
  )
}

export default JobsBanner
