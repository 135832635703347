import React from "react"
import { graphql } from "gatsby"
import styled from "styled-components"
import "semantic-ui-css/components/sticky.min.css"

import Layout from "../components/layout"
import SEO from "../components/seo"
import Contact from "../components/contact"
import { RichText } from "../api/contentful"
import JobsBanner from "../components/jobs-banner"
import {
  UnstyledLink,
  Section,
  Pagehead,
  PageTitle,
  Breadcrumbs,
  ApplicationCallToAction,
} from "../components/shared"
import statics from "../images/statics.svg"
import construction from "../images/construction.svg"
import admin from "../images/admin.svg"

const illustrationByName = {
  Statik: statics,
  Konstruktion: construction,
  Administration: admin,
}

const Jobs = styled.ul`
  padding: 0;
  list-style: none;
`

const Job = styled.li`
  margin-bottom: 5rem;
  display: grid;
  grid-template-columns: 2fr 5fr 2fr;
  grid-template-areas:
    "illustration title       title"
    "illustration description information"
    "illustration application information";
  gap: 1rem;
  column-gap: 2rem;
  @media only screen and (max-width: 666px) {
    width: 80%;
    grid-template-columns: 1fr 6fr;
    grid-template-areas:
      "illustration title"
      "illustration description"
      "illustration information"
      "illustration application";
  }
  @media only screen and (max-width: 480px) {
    grid-template-columns: 1fr 6fr;
    width: 100%;
  }
`

const Illustration = styled.div`
  text-align: right;
  grid-area: illustration;
  @media only screen and (max-width: 480px) {
    img {
      width: 100%;
    }
  }
`

const JobTitle = styled.h3`
  grid-area: title;
  margin: 0;
  font-size: 2.5rem;
  line-height: 1;
  @media only screen and (max-width: 480px) {
    font-size: 1.9rem;
  }
`

const Description = styled.div`
  grid-area: description;
  margin: 0;
`

const Information = styled.div`
  grid-area: information;
`

const InformationList = styled.dl`
  position: sticky;
  top: 100px;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-auto-rows: min-content;
  grid-auto-flow: row;
`

const InformationKey = styled.dt`
  padding: 0.3em 0.3em 0.3em 0;
  font-weight: bold;
  ${props => props.$fullWidth && `padding-top: 2em; grid-column: 1 / -1;`}

  &:not(:first-of-type) {
    border-top: 2px solid;
  }
`

const InformationValue = styled.dd`
  margin: 0;
  padding: 0.3em 0;
  ${props => props.$fullWidth && `grid-column: 1 / -1;`}

  &:not(:first-of-type) {
    border-top: 2px solid;
  }
`

const Application = styled.div`
  grid-area: application;
`

const JobsPage = ({ data }) => {
  const pageData = data.contentfulJobsPage

  return (
    <Layout>
      <SEO title={pageData.title} description={pageData.metaDescription} />
      <Pagehead>
        <PageTitle>
          <RichText node={pageData.pageTitle} unwrapParagraphs />
        </PageTitle>
        <Breadcrumbs nodes={[{ title: "Jobs" }]} />
      </Pagehead>
      <Section>
        <Jobs>
          {pageData.vacancies.map(vacancy => (
            <Job key={vacancy.title}>
              <Illustration>
                <img
                  src={illustrationByName[vacancy.illustrationName]}
                  alt=""
                />
              </Illustration>
              <JobTitle>{vacancy.title}</JobTitle>
              <Description>
                <RichText node={vacancy.description} />
              </Description>
              <Information>
                <InformationList>
                  <InformationKey>Beginn</InformationKey>
                  <InformationValue>
                    {Intl.DateTimeFormat("de-DE", {
                      day: "numeric",
                      month: "short",
                      year: "numeric",
                    }).format(new Date(vacancy.startDate))}
                  </InformationValue>

                  <InformationKey>Freie Stellen</InformationKey>
                  <InformationValue>{vacancy.numAvailable}</InformationValue>

                  <InformationKey $fullWidth>Kontaktperson</InformationKey>
                  <InformationValue $fullWidth>
                    {vacancy.contactPerson.firstName}{" "}
                    {vacancy.contactPerson.lastName}
                    <br />
                    <UnstyledLink
                      to={`tel:${vacancy.contactPerson.phoneNumber
                        .replace(/\D/g, "")
                        .replace(/^0/, "+49")}`}
                    >
                      {vacancy.contactPerson.phoneNumber}
                    </UnstyledLink>
                  </InformationValue>
                </InformationList>
              </Information>
              <Application>
                {/* TODO: subject phrasing */}
                <ApplicationCallToAction
                  initialSubject={`Bewerbung auf ${vacancy.title}`}
                >
                  Bewerben
                </ApplicationCallToAction>
              </Application>
            </Job>
          ))}
        </Jobs>
      </Section>
      <JobsBanner withPageLink={false} />
      <Contact withBranches />
    </Layout>
  )
}

export default JobsPage

export const query = graphql`
  query JobsQuery {
    contentfulJobsPage {
      title
      metaDescription
      pageTitle {
        json
      }
      vacancies {
        illustrationName
        title
        description {
          json
        }
        startDate
        numAvailable
        contactPerson {
          firstName
          lastName
          phoneNumber
        }
      }
    }
  }
`
